<template>
    <!---Header--->
		<div class="ms_header">
			<div class="ms_top_left">
				<form class='' @submit.prevent="SearchData()">
				<div class="ms_top_search desktop" style="margin-right: 30px;">
					<input v-model="search" type="text" class="form-control" placeholder="بحث..">
					<span class="search_icon">
						<img src="../../assets/search.svg" alt="">
					</span>
				</div>
				</form>
				<!-- <div class="ms_top_search" style="margin-right: 30px;">
					<input type="text" class="form-control" placeholder="Search Music Here..">
					<span class="search_icon">
						<img src="../../assets/search.svg" alt="">
					</span>
				</div> -->
				<!-- <div class="ms_top_trend">
					<span><a href="#"  class="ms_color">Trending Songs :</a></span> <span class="top_marquee"><a href="#">Dream your moments, Until I Met You, Gimme Some Courage, Dark Alley (+8 More)</a></span>
				</div> -->
			</div>
			<div class="ms_top_right">
				<!-- <div class="ms_top_lang">
					<span data-bs-toggle="modal" data-bs-target="#lang_modal">languages <img src="assets/images/svg/lang.svg" alt=""></span>
				</div> -->
				<div class="ms_top_btn" style="display: flex;" v-if="showHeader">
					<router-link to="/Unsubscribes" class="ms_btn login_btn Width768 pointer"><span>الغاء الاشتراك</span></router-link>
                 </div>
				<div class="ms_top_btn" style="display: flex;" v-else>
					<a @click="Subscrib()" class="ms_btn reg_btn Width768 pointer" ><span>اشتراك</span></a>
					<router-link to="/Login" class="ms_btn login_btn Width768"><span>تسجيل الدخول</span></router-link>
					<!-- <router-link to="/" ><img src="../../assets/Zain_logo.png" class="reg_btn logoSvg" alt=""></router-link> -->
                 </div>
				 <div class="ms_top_btn" style="text-align: left;line-height: 30px;max-height: 45px;">
					
					<router-link to="/" ><img src="../../assets/Zain_logo.png" class="reg_btn logoSvg" alt=""></router-link>
                 </div>
			</div>
			
			
		</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { watchEffect } from '@vue/runtime-core';
import { HTTPDSP } from '@/Api/http-dsp';
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppHeader',
   setup() {
        const router = useRouter();
        const route = useRoute();
        const search = ref([]);
        const cookie = useCookie()
        const showHeader = ref(true);

		 watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                        // cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            //    cookie.removeCookie("msisdn");
            }
        });  

        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=6245427249", "_self");
        }

        const SearchData = () => {
            router.push({ name: "Searchs", params: { search_id: search.value } });
        }
        return { search, SearchData, Subscrib, showHeader };
    },
   mounted() {
        const s = document.createElement('script');
           s.type = 'text/javascript';
           s. src = '/static/js/custom.js';
           document.body.appendChild(s);
   }
}
</script>

<style>
.logoSvg {
	width: 20%;
margin-right: 3%;
max-width: 572px;
margin-top: -15px;
}
@media(max-width:992px) {
.logoSvg {
		width: 20%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:991px) {
.logoSvg {
		width: 30%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:815px) {
.logoSvg {
		width: 35%;
        margin-bottom: 1%;
        margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:768px) {
.logoSvg {
		width: 20%;
		margin-bottom: 2%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:667px) {
.logoSvg {
		width: 30%;
		margin-bottom: 4%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:600px) {
.logoSvg {
		width: 30%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:480px) {
.logoSvg {
		width: 27%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:414px) {
.logoSvg {
		width: 35%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(max-width:360px) {
.logoSvg {
		width: 50%;
		margin-bottom: 5%;
		margin-right: 5%;
		margin-top: -3px;
}
}
@media(min-width:1024px) {
.logoSvg {
		margin-top:-3px;
}
}
</style>