<template>
    <!---Side Menu Start--->
        <div class="ms_sidemenu_wrapper">
            <div class="ms_nav_close">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
            <div class="ms_sidemenu_inner">
                <div class="ms_logo_inner">
                    <div class="ms_logo">
                        <router-link to="/"><img src="../../assets/images/logo.png" alt="" class="img-fluid" style="width: 80%;"/></router-link>
                    </div>
                    <div class="ms_logo_open">
                        <router-link to="/"><img src="../../assets/images/logo.png" alt="" class="img-fluid" style="width: 80%;"/></router-link>
                    </div>
                </div>
                <div class="ms_nav_wrapper">
                    <ul>
                        <li><router-link to="/" class="active" title="Discover">
						<span class="nav_icon">
							<span class="icon icon_discover"></span>
						</span>
						<span class="nav_text">
							الصفحة الرئيسية
						</span>
						</router-link>
                        </li>
                        <li v-for="(cat, index) in categorie" :key="index"><a @click="getCategorie(cat)" class="pointer" :title="cat.name">
						<span class="nav_icon">
							<span class="icon icon_music"></span>
						</span>
						<span class="nav_text">
							{{ cat.name}}
						</span>
						</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
export default {
  name: 'AppSideMenu',
  setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const categorie = ref([]);

      try {
         HTTP.get('GetAllCategories.php').then((res) => {
            categorie.value = res.data.Categories; 
        });
      } catch (err) {
        console.log(err);
      }
      const getCategorie = (cat) => {
          router.push({ name: "Categories", params: { cat_id: cat.id, cat_name: cat.name } });
      };
      
      return { categorie,getCategorie };
   },
//    mounted() {
//         const s = document.createElement('script');
//            s.type = 'text/javascript';
//            s. src = '/static/js/custom.js';
//            document.body.appendChild(s);
//    }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>